import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import { Heading, Paragraph } from "common/core/typography";
import { WistiaPlayer } from "common/core/wistia_video";
import { useProofFrameContext } from "common/proof_frame/context";

import Styles from "./index.module.scss";

const videoBlocks = [
  {
    heading: (
      <FormattedMessage
        id="007ec72f-1e12-457f-bb81-e22bea9b64c2"
        defaultMessage="The notarization experience for business signers"
      />
    ),
    subheading: (
      <FormattedMessage
        id="28874a68-8bed-450d-917b-e77468b30c92"
        defaultMessage="See what your signers will experience when you initiate a notarization request."
      />
    ),
    videoId: "03bxqhw4cy",
  },
  {
    heading: (
      <FormattedMessage
        id="2a939e9a-da26-4d2b-9925-bfa075608ec6"
        defaultMessage="Introducing Identify with Proof"
      />
    ),
    subheading: (
      <FormattedMessage
        id="cdcf5e9e-3eea-4527-8dc2-ceffa6b4c8ce"
        defaultMessage="Introducing Proof's simple, reusable identity verification flow: Identify."
      />
    ),
    videoId: "3ddihm3o12",
  },
  {
    heading: (
      <FormattedMessage
        id="f1c56ae1-1fd7-478d-92d4-835b4b5ba505"
        defaultMessage="Online notarizations for business"
      />
    ),
    subheading: (
      <FormattedMessage
        id="8d9de3d5-7a32-4a9c-8b31-9088489c3b22"
        defaultMessage="See the full end-to-end experience, from sending to receiving final notarized documents."
      />
    ),
    videoId: "5zypgqupps",
  },
  {
    heading: (
      <FormattedMessage
        id="2d1894f3-26d7-4561-bd21-560110e51f4a"
        defaultMessage="Creating document templates"
      />
    ),
    subheading: (
      <FormattedMessage
        id="7cf80ceb-6dad-45e4-a9dd-0851f65d32f9"
        defaultMessage="Learn how to prepare and tag a document once, then save it as a reusable template."
      />
    ),
    videoId: "gxjo1rfy4u",
  },
  {
    heading: (
      <FormattedMessage
        id="e576f08a-e9f6-490b-9b4c-843f5a18db04"
        defaultMessage="Getting started with EasyLinks"
      />
    ),
    subheading: (
      <FormattedMessage
        id="780eac15-3202-4377-9a75-8ef6ce69f6d3"
        defaultMessage="Create unique, reusable transaction links. Use a document template or let signers upload their own document."
      />
    ),
    videoId: "t8yzx23qo6",
  },
];

export function MobileDeviceGetStarted() {
  const { resetScroll } = useProofFrameContext();

  useEffect(() => {
    resetScroll();
  }, []);

  return (
    <div className={Styles.pageWrapper}>
      <header className={Styles.header}>
        <Heading level="h1" textStyle="headingFour" className={Styles.heading}>
          <FormattedMessage
            id="9aab3858-22bb-4acb-a77b-cd9926eecb90"
            defaultMessage="Welcome to <b>Proof for Business</b>"
            values={{
              b: (text) => <b>{text}</b>,
            }}
          />
        </Heading>
        <Paragraph size="large" className={Styles.subheading}>
          <FormattedMessage
            id="2d441df7-1d36-40cd-90aa-b9028c171c9a"
            defaultMessage="Proof for Business functionality is limited on mobile devices."
          />
        </Paragraph>
        <Paragraph>
          <FormattedMessage
            id="768e227a-37ea-4944-859f-313a10e58da3"
            defaultMessage="Log in from your computer to send transactions and configure your account. In the meantime, check out the videos below."
          />
        </Paragraph>
      </header>
      <div className={Styles.videoBlocks}>
        {videoBlocks.map((block) => (
          <div key={block.videoId} className={Styles.videoBlock}>
            <Heading level="h2" textStyle="headingFive" className={Styles.videoHeading}>
              {block.heading}
            </Heading>
            <Paragraph className={Styles.videoSubheading}>{block.subheading}</Paragraph>
            <WistiaPlayer videoId={block.videoId} />
          </div>
        ))}
      </div>
    </div>
  );
}
