import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";
import { useState } from "react";

import { useForm } from "common/core/form";
import { OrganizationApiKeyPayer } from "graphql_globals";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { QueryWithLoading } from "util/graphql/query";
import { FormPageLayout } from "common/dashboard/common";
import { useMoveTools } from "util/feature_detection";
import { ToolsRedirect } from "util/routes";

import ReferralCampaignEditQuery, {
  type ReferralCampaignEdit_node_ReferralCampaign as ReferralCampaign,
} from "./referral_edit.query.graphql";
import {
  COMMON_MESSAGES,
  NameInput,
  RadioAccountBranding,
  RadioReferralPayer,
  type ReferralsCampaignFormType,
  useNavigateToReferralsDashaboard,
} from "./common";
import UpdateReferralCampaign from "./update_referral.mutation.graphql";

const MESSAGES = defineMessages({
  updateReferralMutationSuccess: {
    id: "513e2bfc-00d6-407a-9801-8228df88cbe9",
    defaultMessage: "{name} has been successfully updated!",
  },
});

function ReferralsEditForm({ campaign }: { campaign: ReferralCampaign }) {
  const intl = useIntl();

  const navigateToReferralsDashboard = useNavigateToReferralsDashaboard();

  const form = useForm<ReferralsCampaignFormType>({
    defaultValues: {
      name: campaign.name,
      payer: campaign.coverPayment
        ? OrganizationApiKeyPayer.ORGANIZATION
        : OrganizationApiKeyPayer.CUSTOMER,
      branding: campaign.useBranding ? "true" : "false",
    },
  });
  const { handleSubmit, formState } = form;
  const updateReferralCampaign = useMutation(UpdateReferralCampaign);
  const [errorMessage, setErrorMessage] = useState<string>();

  const moveTools = useMoveTools();
  if (!useMatch({ path: "tools", end: false }) && moveTools) {
    return ToolsRedirect();
  }

  return (
    <FormPageLayout
      heading={
        <FormattedMessage
          id="7c881db4-cafa-4c35-8a73-46fc9caf48d8"
          defaultMessage="Edit referral campaign"
        />
      }
      title={intl.formatMessage(COMMON_MESSAGES.campaignDetails)}
      loading={formState.isSubmitting}
      submitDisabled={!formState.isDirty}
      submitText={
        <FormattedMessage
          id="5ac5880b-177f-42ec-9ad8-c1c470e844ab"
          defaultMessage="Save campaign"
        />
      }
      onSubmit={handleSubmit((values) => {
        setErrorMessage(undefined);
        return updateReferralCampaign({
          variables: {
            input: {
              referralCampaignId: campaign.id,
              name: values.name,
            },
          },
        })
          .then((response) => {
            const updatedCampaign = response.data!.updateReferralCampaign!.referralCampaign;
            pushNotification({
              position: "topCenter",
              message: intl.formatMessage(MESSAGES.updateReferralMutationSuccess, {
                name: <strong>{updatedCampaign.name}</strong>,
              }),
            });

            const status = updatedCampaign.active ? "active" : "inactive";
            navigateToReferralsDashboard(status);
          })
          .catch(() => {
            setErrorMessage(intl.formatMessage(COMMON_MESSAGES.referralMutationError));
          });
      })}
      cancelText={
        <FormattedMessage
          id="bfe398ff-c846-4739-8742-1610a892a629"
          defaultMessage="Cancel and return to dashboard"
        />
      }
      onCancel={() => {
        const status = campaign.active ? "active" : "inactive";
        navigateToReferralsDashboard(status);
      }}
      errorMessage={errorMessage}
    >
      <NameInput form={form} />
      <RadioAccountBranding disabled form={form} />
      <RadioReferralPayer disabled form={form} />
    </FormPageLayout>
  );
}

export function EditReferralCampaign() {
  const { campaignId } = useParams();
  return (
    <QueryWithLoading query={ReferralCampaignEditQuery} variables={{ campaignId: campaignId! }}>
      {({ data }) => <ReferralsEditForm campaign={data!.node! as ReferralCampaign} />}
    </QueryWithLoading>
  );
}
