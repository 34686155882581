import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useMatch } from "react-router-dom";
import { useCallback, useState } from "react";

import { captureException } from "util/exception";
import {
  Sidebar,
  SidebarTabButton,
  SidebarTabButtonSublabel,
  SidebarSectionHeader,
  SidebarTabLink,
} from "common/sidebar";
import {
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
} from "common/dashboard/filter_dropdown/common";
import {
  useDashboardSegmentTrack,
  useShowDocTemplates,
  useShowEasylinks,
} from "common/dashboard/util";
import { useTagUserMutation } from "common/user/tag";
import { useFeatureFlag } from "common/feature_gating";
import { SEGMENT_EVENTS } from "constants/analytics";
import { TEMPLATE_SUPPORT_URL } from "constants/support";
import { BUSINESS_TEMPLATE_NEW_FEATURE } from "constants/feature_gates";
import {
  businessDashboardDeserializer,
  businessDashboardSerializer,
} from "business_portal/dashboard/filter";
import { useFilter } from "common/dashboard/filter";
import { EASYLINK_DASHBOARD_PATH, TEMPLATES_DASHBOARD_PATH, TRANSACTION_PATH } from "util/routes";
import {
  useIAnav,
  useMoveTools,
  useProofDefend,
  useProofDefendUpsell,
} from "util/feature_detection";
import { FeatureAnnouncement } from "common/core/announcements";
import { Badge } from "common/core/badge";

import {
  type BusinessDashboard_node_Organization as ActiveOrganization,
  type BusinessDashboard_viewer_user as User,
} from "./index.query.graphql";
import { BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG } from ".";
import { REFERRALS_PATH } from "./referrals/common";

type Props = {
  showCreatorGroups: boolean;
  activeOrganization: ActiveOrganization;
  user: User;
};

const MESSAGES = defineMessages({
  transactions: {
    id: "b5ebad8d-45f3-4caa-ab93-3ff92917f091",
    defaultMessage: "Transactions",
  },
  tools: {
    id: "1ace8fb7-43f1-47b6-a3a8-374ddb499a8f",
    defaultMessage: "Tools",
  },
  newToolTip: {
    id: "c33f7d82-8335-48e6-8197-9171f7ecb474",
    defaultMessage: "A new hub for creating, managing, and editing EasyLinks.",
  },
  sidebarNavIA: {
    id: "0405b38f-43dd-4645-8512-4de1b4f941e5",
    defaultMessage: "Send & manage",
  },
  sidebar: {
    id: "4ec9401e-d991-4e6c-b81a-3a262ca0ef7d",
    defaultMessage: "Send & manage",
  },
  newTemplateFeatureHeading: {
    id: "a558a5e9-3340-4449-b19c-6398deae4d29",
    defaultMessage: "New document template feature: Automatic matching",
  },
  newTemplateFeatureDescription: {
    id: "53be718a-f609-4aca-889d-c531636874bd",
    defaultMessage:
      "Documents will automatically match to templates created in your Document Templates library.",
  },
  newReferralFeatureHeading: {
    id: "81b1b831-7438-412a-975c-692fbdf5a1be",
    defaultMessage: "New feature: Referral campaigns!",
  },
  newReferralFeatureDescription: {
    id: "3c074c6a-6037-472c-8f3a-928562a5406b",
    defaultMessage:
      "Introduce the ease and convenience of Notarize to customers, clients, and affiliates with custom referral campaigns.",
  },
  newReferralFeatureDescriptionProof: {
    id: "2b6c6df6-a24c-4524-a871-a8508ab22357",
    defaultMessage:
      "Introduce the ease and convenience of online notarizations to customers, clients, and affiliates with custom referral campaigns.",
  },
});

function useSidebarFilters() {
  const { handleChange, deserializedArgs } = useFilter(
    businessDashboardDeserializer,
    businessDashboardSerializer,
    TRANSACTION_PATH,
  );
  const { section: deserializedSection } = deserializedArgs;

  const selectAllTransactionTab = useCallback(() => {
    handleChange(
      {
        section: TransactionSectionNamespace.ALL,
        subSection: TransactionSubsectionNamespace.DEFAULT,
      },
      deserializedSection !== TransactionSectionNamespace.ALL,
    );
  }, [handleChange]);
  const selectCreatedByMeSubTab = useCallback(() => {
    handleChange(
      {
        section: TransactionSectionNamespace.ALL,
        subSection: TransactionSubsectionNamespace.CREATED_BY_ME,
      },
      deserializedSection !== TransactionSectionNamespace.ALL,
    );
  }, [handleChange]);
  const selectIdentityRiskSubTab = useCallback(() => {
    handleChange(
      {
        section: TransactionSectionNamespace.ALL,
        subSection: TransactionSubsectionNamespace.IDENTITY_RISK,
      },
      deserializedSection !== TransactionSectionNamespace.ALL,
    );
  }, [handleChange]);
  const selectTabByName = useCallback(
    (section: TransactionSectionNamespace) => {
      handleChange(
        {
          section,
        },
        deserializedSection !== section,
      );
    },
    [handleChange],
  );
  return {
    deserializedArgs,
    selectAllTransactionTab,
    selectCreatedByMeSubTab,
    selectIdentityRiskSubTab,
    selectTabByName,
  };
}

type ExplicitSubTab = TransactionSubsectionNamespace;

const getSubTab = (
  section: TransactionSectionNamespace,
  subSection: TransactionSubsectionNamespace | null,
): ExplicitSubTab | null => {
  if (
    section === TransactionSectionNamespace.ALL &&
    subSection &&
    [
      TransactionSubsectionNamespace.CREATED_BY_ME,
      TransactionSubsectionNamespace.IDENTITY_RISK,
    ].includes(subSection)
  ) {
    return subSection;
  }

  return null;
};

function BusinessDashboardSidebar({ showCreatorGroups, activeOrganization, user }: Props) {
  const intl = useIntl();
  const proofDefendUpsell = useProofDefendUpsell(activeOrganization);
  const proofDefend = useProofDefend(activeOrganization);
  const dashboardSegmentTrack = useDashboardSegmentTrack();

  const transactionsActive = Boolean(useMatch({ path: TRANSACTION_PATH, end: false }));

  const bizTemplatesNewFeatureEnabled = useFeatureFlag(BUSINESS_TEMPLATE_NEW_FEATURE);
  const [newTemplateFeatureDismissed, setNewTemplateFeatureDismissed] = useState(
    user.tags?.some((t) => t?.tag === BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG),
  );

  const moveTools = useMoveTools();
  const referralsEnabled = useFeatureFlag("transaction-referrals");
  const showTemplates = useShowDocTemplates(activeOrganization.featureList);
  const showEasyLink = useShowEasylinks(activeOrganization.featureList);

  const {
    deserializedArgs,
    selectAllTransactionTab,
    selectCreatedByMeSubTab,
    selectIdentityRiskSubTab,
    selectTabByName,
  } = useSidebarFilters();

  const { section, subSection } = deserializedArgs;

  const subTab = getSubTab(section, subSection);

  const tagUserMutateFn = useTagUserMutation();

  const onDismissTemplateAnnouncement = async () => {
    try {
      await tagUserMutateFn({
        variables: {
          input: {
            userId: user.id,
            tag: BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG,
          },
          userTagList: [BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG],
        },
      });
      setNewTemplateFeatureDismissed(true);
    } catch {
      captureException(
        new Error(`Error adding templates announcement dimissed tag for user ${user.id}`),
      );
    }
  };

  const sidebarTitle = useIAnav()
    ? intl.formatMessage(MESSAGES.sidebarNavIA)
    : intl.formatMessage(MESSAGES.sidebar);

  return (
    <Sidebar title={sidebarTitle}>
      {!moveTools && <SidebarSectionHeader title={intl.formatMessage(MESSAGES.transactions)} />}
      <SidebarTabButton
        active={transactionsActive && section === TransactionSectionNamespace.ALL && !subTab}
        onClick={() => {
          if (
            section !== TransactionSectionNamespace.ALL ||
            subSection !== TransactionSubsectionNamespace.DEFAULT
          ) {
            dashboardSegmentTrack(SEGMENT_EVENTS.SCOPE_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_TRANSACTIONS",
            });
            selectAllTransactionTab();
          }
        }}
        automationId="transaction-filter-all"
      >
        <FormattedMessage
          id="cbbdbd4a-9a53-4c64-ad0a-be7496c1dcf3"
          defaultMessage="All transactions"
        />
        <SidebarTabButtonSublabel>
          {activeOrganization.allTransactionsCount.totalCount}
        </SidebarTabButtonSublabel>
      </SidebarTabButton>
      {showCreatorGroups && (
        <SidebarTabButton
          active={transactionsActive && subTab === TransactionSubsectionNamespace.CREATED_BY_ME}
          onClick={() => {
            if (!transactionsActive || subTab !== TransactionSubsectionNamespace.CREATED_BY_ME) {
              dashboardSegmentTrack(SEGMENT_EVENTS.SCOPE_FILTER_SELECTED, {
                value: "created_by_me",
              });
              selectCreatedByMeSubTab();
            }
          }}
          isSubItem
        >
          <FormattedMessage
            id="4f27fdee-32f9-436c-8ad6-270cf276dcd3"
            defaultMessage="Created by me"
          />
          <SidebarTabButtonSublabel>
            {activeOrganization.createdByMeTransactionsCount.totalCount}
          </SidebarTabButtonSublabel>
        </SidebarTabButton>
      )}
      {(proofDefend || proofDefendUpsell) && (
        <SidebarTabButton
          active={transactionsActive && subTab === TransactionSubsectionNamespace.IDENTITY_RISK}
          isSubItem
          onClick={() => {
            if (transactionsActive && subTab === TransactionSubsectionNamespace.IDENTITY_RISK) {
              return;
            }
            if (proofDefend || proofDefendUpsell) {
              dashboardSegmentTrack(SEGMENT_EVENTS.SCOPE_FILTER_SELECTED, {
                value: "identity_risk",
              });
              selectIdentityRiskSubTab();
            }
          }}
        >
          <FormattedMessage
            id="744ac570-a0a0-4831-854f-6b17d7c0df73"
            defaultMessage="Identity risk"
          />
          {proofDefendUpsell ? (
            <SidebarTabButtonSublabel>
              <Badge kind="new">
                <FormattedMessage
                  id="84083f64-836e-4005-a174-da49933d68bc"
                  defaultMessage="Upgrade"
                />
              </Badge>
            </SidebarTabButtonSublabel>
          ) : (
            <SidebarTabButtonSublabel>
              {activeOrganization.identityRiskTransactionsCount.totalCount}
            </SidebarTabButtonSublabel>
          )}
        </SidebarTabButton>
      )}
      <SidebarTabButton
        active={transactionsActive && section === TransactionSectionNamespace.ARCHIVED}
        onClick={() => {
          selectTabByName(TransactionSectionNamespace.ARCHIVED);
        }}
        automationId="transaction-filter-archived"
      >
        <FormattedMessage id="9c5116b0-1fd8-417d-8117-2fbbaa19a2fb" defaultMessage="Archived" />
        <SidebarTabButtonSublabel>
          {activeOrganization.archivedTransactionsCount.totalCount}
        </SidebarTabButtonSublabel>
      </SidebarTabButton>
      {!moveTools && (showEasyLink || showTemplates || referralsEnabled) && (
        // show section if any of easylinks or templates should be shown
        <>
          <SidebarSectionHeader title={intl.formatMessage(MESSAGES.tools)} />
          {referralsEnabled && (
            <SidebarTabLink to={REFERRALS_PATH}>
              <FormattedMessage
                id="9f2a5219-a45f-47de-b7f6-4749960e6919"
                defaultMessage="Referral campaigns"
              />
              <SidebarTabButtonSublabel>
                {activeOrganization.referralCampaigns.totalCount}
              </SidebarTabButtonSublabel>
            </SidebarTabLink>
          )}
          {showEasyLink && (
            <SidebarTabLink to={EASYLINK_DASHBOARD_PATH}>
              <FormattedMessage
                id="4ddb3eb2-2fb6-4233-9ed3-9b616c498a91"
                defaultMessage="EasyLinks"
              />
              <SidebarTabButtonSublabel>
                {activeOrganization.easylinks.totalCount}
              </SidebarTabButtonSublabel>
            </SidebarTabLink>
          )}
          {showTemplates && (
            <SidebarTabLink to={TEMPLATES_DASHBOARD_PATH}>
              <FormattedMessage
                id="3ba1e239-c694-40ef-a06c-0b1f9d0b2431"
                defaultMessage="Document templates"
              />
              <SidebarTabButtonSublabel>
                {activeOrganization.templates.totalCount}
              </SidebarTabButtonSublabel>
            </SidebarTabLink>
          )}
        </>
      )}
      {!newTemplateFeatureDismissed && bizTemplatesNewFeatureEnabled && showTemplates && (
        <FeatureAnnouncement
          heading={intl.formatMessage(MESSAGES.newTemplateFeatureHeading)}
          description={intl.formatMessage(MESSAGES.newTemplateFeatureDescription)}
          onDismiss={onDismissTemplateAnnouncement}
          learnMoreLink={TEMPLATE_SUPPORT_URL}
        />
      )}
    </Sidebar>
  );
}

export { BusinessDashboardSidebar };
