import { useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSearchParams, useMatch } from "react-router-dom";

import { useSenderRolePhase3, useMoveTools } from "util/feature_detection";
import TableSearch from "common/core/table/search";
import {
  DashboardActionBar,
  DashboardBody,
  DashboardContent,
  DashboardFilters,
  DashboardTitle,
} from "common/dashboard";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import { serializerFactory } from "common/dashboard/filter/serializers";
import { useActiveOrganization } from "common/account/active_organization";
import { useMutation, useQuery } from "util/graphql";
import TablePagination, { usePagination, useSetPageIndex } from "common/core/table/pagination";
import Table from "common/core/table";
import { FormattedDate } from "common/core/format/date";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import LoadingIndicator from "common/core/loading_indicator";
import { maxPageNumber, paginationVariables } from "util/pagination";
import { ToolsRedirect } from "util/routes";
import {
  EmptyStateButtons,
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateTitle,
  HowItWorksLink,
} from "common/dashboard/empty_state";
import Link from "common/core/link";
import { REFERRALS_SUPPORT_URL } from "constants/support";
import { useFeatureFlag } from "common/feature_gating";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { REFERRAL_CAMPAIGNS_P2 } from "constants/feature_gates";
import { usePermissions } from "common/core/current_user_role";
import {
  DashboardTabs,
  DeactivateModal,
  useActivateOrDeactivateToast,
} from "common/dashboard/common";

import ReferralsQuery, {
  type Referrals_organization_Organization as Organization,
  type Referrals_organization_Organization_referralCampaigns_edges_node as Campaigns,
} from "./index.query.graphql";
import { ReferralShareModal } from "./share_modal";
import UpdateReferralCampaignMutation from "./update_referral_campaign_mutation.graphql";

const MESSAGES = defineMessages({
  searchPlacholder: {
    id: "1035638d-91c3-442a-8559-6749c6be3990",
    defaultMessage: "Search by name...",
  },
  searchLabel: {
    id: "2eb3e7ee-dca7-4511-866f-12b1158c3dff",
    defaultMessage: "Search referral campaigns by name",
  },
  actionsLabel: {
    id: "120b7f0f-aa09-4192-abca-9975b1d178ec",
    defaultMessage: "Actions menu",
  },
  tabsLabel: {
    id: "5c354754-8fc2-43cd-9951-994932662c42",
    defaultMessage: "Referral campaigns activation status",
  },
  deactivateModalWarningText: {
    id: "d22391ff-25d3-4adb-aa67-75194191c2e2",
    defaultMessage:
      "Referral links associated with this campaign will become inaccessible. The campaign can be reactivated at any time.",
  },
});

function referralsDeserializer(queryArgs: URLSearchParams) {
  return {
    query: queryArgs.get("query"),
    status: queryArgs.get("status"),
    page: Number(queryArgs.get("page") || 0),
  };
}

const referralsSerializer = serializerFactory({
  query: (arg: string | null) => arg || null,
  status: (arg: string | null) => arg || null,
  page: (arg: number) => arg.toString(),
});

const PAGE_SIZE = 50;

function CreateNewCampaignLink() {
  return (
    <ButtonStyledLink
      to="/referrals/new"
      variant="primary"
      buttonColor="action"
      withIcon={{ name: "add-1", placement: "right" }}
    >
      <FormattedMessage
        id="8145527a-e4ee-4284-8c72-03ead2114030"
        defaultMessage="Create new campaign"
      />
    </ButtonStyledLink>
  );
}

function EmptyState() {
  const referralsP2Enabled = useFeatureFlag(REFERRAL_CAMPAIGNS_P2);
  const { hasPermissionFor } = usePermissions();
  const senderRolePhase3Flag = useSenderRolePhase3();

  return (
    <EmptyStateContainer>
      <EmptyStateTitle>
        {referralsP2Enabled ? (
          <FormattedMessage
            id="cccb553c-2ef8-40ce-bdd5-ca12ad59602d"
            defaultMessage="Share the online notarization experience with clients and affiliates."
          />
        ) : (
          <FormattedMessage
            id="f1c0ec8a-f254-44e8-992b-d9698592e81e"
            defaultMessage="Create a referral campaign"
          />
        )}
      </EmptyStateTitle>
      <EmptyStateDescription>
        {referralsP2Enabled ? (
          <FormattedMessage
            id="9494a088-ae20-41d3-8ea6-8827f677188e"
            defaultMessage="Introduce the ease and convenience of online notarizations using custom referral campaigns. Your referrals will connect with a Notarize Network notary on Proof, and their completed transactions will not appear in your Proof account."
          />
        ) : (
          <FormattedMessage
            id="903f72ef-5612-45af-b15d-e15d4aacabf3"
            defaultMessage="Your account has no referral campaigns."
          />
        )}
      </EmptyStateDescription>
      {referralsP2Enabled && (
        <EmptyStateButtons>
          {senderRolePhase3Flag ? (
            hasPermissionFor("createReferralCampaigns") && <CreateNewCampaignLink />
          ) : (
            <CreateNewCampaignLink />
          )}
          <HowItWorksLink href={REFERRALS_SUPPORT_URL} />
        </EmptyStateButtons>
      )}
    </EmptyStateContainer>
  );
}

function EmptySearch() {
  return (
    <EmptyStateContainer>
      <EmptyStateTitle>
        <FormattedMessage
          id="d54482f2-5dfd-452e-be58-a1726f7bdddf"
          defaultMessage="No results found"
        />
      </EmptyStateTitle>
      <EmptyStateDescription>
        <FormattedMessage
          id="428d93b7-4782-433c-8a8b-5ce77ea42e48"
          defaultMessage="Try adjusting your search."
        />
      </EmptyStateDescription>
    </EmptyStateContainer>
  );
}

function EmptyStateInactive() {
  return (
    <EmptyStateContainer>
      <EmptyStateTitle>
        <FormattedMessage
          id="5af55079-8f3a-4734-a9bf-3ec5aab389e1"
          defaultMessage="No inactive campaigns."
        />
      </EmptyStateTitle>
      <EmptyStateDescription>
        <FormattedMessage
          id="8a799fb6-f886-4ba3-8b17-798313196a29"
          defaultMessage="Campaigns that have been made inactive will appear here. They can be activated again at any time."
        />
      </EmptyStateDescription>
    </EmptyStateContainer>
  );
}

type ReferralsTableProps = {
  loading: boolean;
  query: ReturnType<typeof referralsDeserializer>["query"];
  items: Campaigns[];
  showNoInactive: boolean;
  onInactiveTab: boolean;
  onActivateOrDeactivate: (id: string, shouldActivate: boolean) => Promise<unknown>;
  sendActivateOrDeactivateToast: (itemIds: string[], isSuccess: boolean) => void;
  referralsP2Enabled: boolean;
};
function ReferralsTable({
  loading,
  query,
  items,
  showNoInactive,
  onInactiveTab,
  onActivateOrDeactivate,
  sendActivateOrDeactivateToast,
  referralsP2Enabled,
}: ReferralsTableProps) {
  const intl = useIntl();
  const [showShareModal, setShowShareModal] = useState<null | { campaignId: string }>(null);
  const [campaignIdsToDeactivate, setCampaignIdsToDeactivate] = useState<string[] | null>(null);
  const { hasPermissionFor } = usePermissions();

  const displayPopoutMenuActions = useSenderRolePhase3()
    ? referralsP2Enabled && hasPermissionFor("updateReferralCampaigns")
    : referralsP2Enabled;

  async function activateCampaign(id: string) {
    try {
      await onActivateOrDeactivate(id, true);
      sendActivateOrDeactivateToast([id], true);
    } catch {
      sendActivateOrDeactivateToast([id], false);
    }
  }

  return (
    <>
      <DashboardBody>
        {items.length ? (
          <Table
            data={items}
            columns={[
              {
                Header: (
                  <FormattedMessage
                    id="79aa58a7-3ec7-4e46-ab5c-2bc64c9352fa"
                    defaultMessage="Name"
                  />
                ),
                render: (item) => item.name,
              },
              {
                Header: (
                  <FormattedMessage
                    id="b059e807-7a91-4eac-87cb-b4804c458e13"
                    defaultMessage="Payment"
                  />
                ),
                render: (item) =>
                  item.coverPayment ? (
                    <FormattedMessage
                      id="0ebddbb8-5883-4d33-954f-a1d8c01b3371"
                      defaultMessage="Org pays"
                    />
                  ) : (
                    <FormattedMessage
                      id="8fd34311-fcee-4c4c-957d-d867329f7ed8"
                      defaultMessage="Signer pays"
                    />
                  ),
              },
              {
                Header: (
                  <FormattedMessage
                    id="c1c12f3b-addd-41b7-9f95-3f076067ab5f"
                    defaultMessage="Date created"
                  />
                ),
                render: (item) => <FormattedDate value={item.createdAt} />,
              },
              {
                Header: <></>,
                render: (item) => {
                  return !onInactiveTab || displayPopoutMenuActions ? (
                    <PopoutMenu
                      aria-label={intl.formatMessage(MESSAGES.actionsLabel)}
                      placement="leftTop"
                      selfManageVerticalAlignment
                    >
                      {({ close }) => (
                        <>
                          {!onInactiveTab && (
                            <PopoutMenuItem
                              iconName="share"
                              onClick={() => {
                                setShowShareModal({ campaignId: item.id });
                              }}
                            >
                              <FormattedMessage
                                id="c8dbe957-07d2-4e55-85ba-4ddfafcc1347"
                                defaultMessage="{singleUse, select, true {Generate a single-use} other {Share} } referral link"
                                values={{ singleUse: item.coverPayment }}
                              />
                            </PopoutMenuItem>
                          )}
                          {displayPopoutMenuActions && (
                            <>
                              <PopoutMenuItem
                                iconName="pencil-line"
                                linkUrl={{ url: `/referrals/edit/${item.id}` }}
                              >
                                <FormattedMessage
                                  id="ee741359-3316-4a08-9b8b-da1171c66a73"
                                  defaultMessage="Edit campaign"
                                />
                              </PopoutMenuItem>

                              {onInactiveTab ? (
                                <PopoutMenuItem
                                  data-automation-id={`popout-menu-referral-campaign-${item.id}-activate`}
                                  iconName="success"
                                  onClick={async () => {
                                    await activateCampaign(item.id);
                                    close();
                                  }}
                                >
                                  <FormattedMessage
                                    id="842f33e0-87bc-4bec-bd9e-6aa8396b8ab8"
                                    defaultMessage="Activate campaign"
                                  />
                                </PopoutMenuItem>
                              ) : (
                                <PopoutMenuItem
                                  iconName="prohibit"
                                  onClick={() => {
                                    setCampaignIdsToDeactivate([item.id]);
                                    close();
                                  }}
                                >
                                  <FormattedMessage
                                    id="ac5a542d-88a3-4811-9b79-7d92f269d66c"
                                    defaultMessage="Deactivate campaign"
                                  />
                                </PopoutMenuItem>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </PopoutMenu>
                  ) : null;
                },
                collapse: true,
                preventClick: true,
              },
            ]}
            totalItems={items.length}
            loading={loading}
            rowInteraction={{
              onClick: (item) => {
                if (onInactiveTab) {
                  return;
                }
                setShowShareModal({ campaignId: item.id });
              },
            }}
          />
        ) : loading ? (
          <LoadingIndicator />
        ) : query ? (
          <EmptySearch />
        ) : showNoInactive ? (
          <EmptyStateInactive />
        ) : (
          <EmptyState />
        )}
      </DashboardBody>
      {showShareModal && (
        <ReferralShareModal
          campaign={items.find((campaign) => campaign.id === showShareModal.campaignId)!}
          onDone={() => setShowShareModal(null)}
        />
      )}

      {campaignIdsToDeactivate?.length === 1 && (
        <DeactivateModal
          idToDeactivate={campaignIdsToDeactivate[0]}
          setIdsToDeactivate={setCampaignIdsToDeactivate}
          sendActivateOrDeactivateToast={sendActivateOrDeactivateToast}
          onDeactivate={onActivateOrDeactivate}
          warningMsg={intl.formatMessage(MESSAGES.deactivateModalWarningText)}
        />
      )}
    </>
  );
}

export default function Referrals() {
  const intl = useIntl();
  const [, setSearchParams] = useSearchParams();
  const { handleChange, deserializedArgs } = useFilter(referralsDeserializer, referralsSerializer);
  const { query, page, status } = deserializedArgs;
  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query);
  const [activeOrganizationId] = useActiveOrganization();
  const { hasPermissionFor } = usePermissions();
  const referralsP2Enabled = useFeatureFlag(REFERRAL_CAMPAIGNS_P2);
  const senderRolePhase3Flag = useSenderRolePhase3();
  const { first, offset } = paginationVariables((page + 1).toString(), PAGE_SIZE);

  const queryVariables = {
    query,
    first,
    offset,
    organizationId: activeOrganizationId!,
    active: status !== "inactive",
  };

  const { data, loading, previousData } = useQuery(ReferralsQuery, {
    variables: queryVariables,
  });

  const organization = (data?.organization || previousData?.organization) as Organization | null;

  const items = useMemo(
    () => organization?.referralCampaigns.edges.map(({ node }) => node) || [],
    [organization],
  );

  const totalCount = organization?.referralCampaigns.totalCount || 0;

  const activeCount = organization?.activeCampaigns.totalCount || 0;
  const inactiveCount = organization?.inactiveCampaigns.totalCount || 0;

  const showNoInactive = Boolean(activeCount) && status === "inactive";
  const showHeaderContent = Boolean(organization?.referralCampaigns.edges.length) || showNoInactive;

  const pageCount = maxPageNumber(totalCount, PAGE_SIZE);
  const { setPageIndex } = useSetPageIndex();

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex: page,
      pageCount,
      pageSize: PAGE_SIZE,
      items,
      onPageChange: setPageIndex,
    });

  const sendActivateOrDeactivateToast = useActivateOrDeactivateToast({
    items,
    action: status === "active" ? "deactivate" : "activate",
    type: "referral",
  });

  const onSelect = (tab: "active" | "inactive") => {
    // Replace true so page resets (gets deserialized to 0 by default)
    handleChange({ status: tab }, true);
  };

  const updateReferralCampaign = useMutation(UpdateReferralCampaignMutation);

  const onActivateOrDeactivate = (id: string, shouldActivate: boolean) => {
    return updateReferralCampaign({
      variables: { input: { referralCampaignId: id, active: shouldActivate }, ...queryVariables },
    });
  };

  const createCampaignButton = senderRolePhase3Flag
    ? showHeaderContent &&
      referralsP2Enabled &&
      hasPermissionFor("createReferralCampaigns") && <CreateNewCampaignLink />
    : showHeaderContent && referralsP2Enabled && <CreateNewCampaignLink />;

  useEffect(() => {
    if (!status) {
      setSearchParams({ status: "active" });
    }
  }, [status]);

  const moveTools = useMoveTools();
  if (!useMatch({ path: "tools", end: false }) && moveTools) {
    return ToolsRedirect();
  }

  return (
    <DashboardContent>
      <DashboardTitle
        title={
          <FormattedMessage
            id="fb1bb2fe-aeb3-4209-8ec9-56f091ea08b3"
            defaultMessage="Referral campaigns"
          />
        }
        description={
          <>
            {senderRolePhase3Flag &&
            !hasPermissionFor("createReferralCampaigns") &&
            !hasPermissionFor("updateReferralCampaigns") ? (
              <FormattedMessage
                id="6608430c-a1ac-419a-bd00-98223e63cff4"
                defaultMessage="This table allows you to view your referral campaigns. Transactions completed from referral campaigns will not appear in your account."
              />
            ) : (
              <FormattedMessage
                id="f5c0ed48-fe9d-4e92-b1b4-deee73c86862"
                defaultMessage="This table allows you to view and manage your referral campaigns. Transactions completed from referral campaigns will not appear in your account."
              />
            )}
            {referralsP2Enabled && (
              <>
                &nbsp;
                <Link href={REFERRALS_SUPPORT_URL}>
                  <FormattedMessage
                    id="526714f1-12cf-4504-9090-2168ec0ddab7"
                    defaultMessage="Learn more"
                  />
                </Link>
              </>
            )}
          </>
        }
        buttons={createCampaignButton}
      />

      {referralsP2Enabled && (
        <DashboardTabs
          activeTab={status}
          onSelect={onSelect}
          aria-label={intl.formatMessage(MESSAGES.tabsLabel)}
          tabs={[
            {
              tabLabel: (
                <FormattedMessage
                  id="583a3f81-d15c-4cc6-8a18-3106916e1eaf"
                  defaultMessage="Active"
                />
              ),
              count: activeCount,
              value: "active",
            },
            {
              tabLabel: (
                <FormattedMessage
                  id="324c44e8-59e2-4d01-bdb9-54c51c20b955"
                  defaultMessage="Inactive"
                />
              ),
              count: inactiveCount,
              value: "inactive",
            },
          ]}
        />
      )}

      {items.length > 0 && (
        <>
          <DashboardFilters>
            <TableSearch
              value={textFilterValue}
              placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
              onChange={handleTextFilterChange}
              aria-label={intl.formatMessage(MESSAGES.searchLabel)}
            />
          </DashboardFilters>

          <DashboardActionBar
            pagination={
              <TablePagination
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                startIndex={startIndex}
                endIndex={endIndex}
                totalCount={totalCount}
              />
            }
            hideExportButton
          />
        </>
      )}

      <ReferralsTable
        referralsP2Enabled={referralsP2Enabled}
        loading={loading}
        query={query}
        items={items}
        showNoInactive={showNoInactive}
        onInactiveTab={status === "inactive"}
        onActivateOrDeactivate={onActivateOrDeactivate}
        sendActivateOrDeactivateToast={sendActivateOrDeactivateToast}
      />
    </DashboardContent>
  );
}
