import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useState } from "react";

import { useForm } from "common/core/form";
import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { AutomaticFormRow } from "common/core/form/layout";
import { defaultRequiredMessage, emailPatternValidation } from "common/core/form/error";
import { EmailTextInput } from "common/core/form/text";
import { pushNotification } from "common/core/notification_center/actions";
import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import { GenerateQrCodeContent, CopyableTextArea } from "common/dashboard/common";
import { useFeatureFlag } from "common/feature_gating";
import { REFERRAL_CAMPAIGNS_P2 } from "constants/feature_gates";
import { Paragraph } from "common/core/typography";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";

import type { Referrals_organization_Organization_referralCampaigns_edges_node as ReferralCampaign } from "../index.query.graphql";
import Styles from "./index.module.scss";
import SendReferralInvitationEmailMutation from "./send_referral_invitation_email_mutation.graphql";
import CreateReferralLink, {
  type CreateReferralLink_createReferralLink as ReferralLink,
} from "./create_referral_link.mutation.graphql";

type EmailTabFormValues = { email: string };

const MESSAGES = defineMessages({
  emailLabel: {
    id: "99650cc0-7587-4e8d-8bb8-1ec036cb8955",
    defaultMessage: "Email address",
  },
  success: {
    id: "9ff1a46e-0658-4011-93c0-f173031a614e",
    defaultMessage: "A referral link for {campaignName} has been sent to {email}!",
  },
  shareTabsLabel: {
    id: "b9a6a342-b757-41c3-8f07-00396cf62564",
    defaultMessage: "Referral campaign share options",
  },
  generateError: {
    id: "8daf426f-5ef5-405c-ad68-522f8f69435b",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
  copyableTextArea: {
    id: "45cce913-5e32-4628-b0ca-d11f58cb7196",
    defaultMessage: "Copyable referral link",
  },
});

function useCreateReferralLink() {
  const intl = useIntl();
  const createReferralLink = useMutation(CreateReferralLink);
  const [referralLink, setReferralLink] = useState<null | ReferralLink>(null);

  function handleCreateLink(id: string) {
    if (referralLink) {
      return;
    }
    createReferralLink({
      variables: {
        input: {
          referralCampaignId: id,
        },
      },
    })
      .then((response) => {
        setReferralLink({
          link: response.data!.createReferralLink!.link,
          qrStream: response.data!.createReferralLink!.qrStream,
        });
      })
      .catch(() => {
        setReferralLink(null);
        pushNotification({
          message: intl.formatMessage(MESSAGES.generateError),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      });
  }

  return { referralLink, handleCreateLink };
}

function EmailTab({ campaign, onDone }: { campaign: ReferralCampaign; onDone: () => void }) {
  const form = useForm<EmailTabFormValues>();
  const sendEmailMutateFn = useMutation(SendReferralInvitationEmailMutation);
  const [error, setError] = useState(false);
  const intl = useIntl();

  return (
    <form
      className={Styles.form}
      onSubmit={form.handleSubmit(async (values) => {
        try {
          await sendEmailMutateFn({
            variables: { input: { referralCampaignId: campaign.id, email: values.email } },
          });
          pushNotification({
            position: "topCenter",
            message: intl.formatMessage(MESSAGES.success, {
              campaignName: campaign.name,
              email: values.email,
            }),
          });
          onDone();
        } catch (e) {
          captureException(e);
          setError(true);
        }
      })}
    >
      {error && (
        <AlertMessage className={Styles.banner} kind="danger">
          <FormattedMessage
            id="3f412ce6-d328-42e0-b9b7-81a344965865"
            defaultMessage="Sorry, something went wrong. Please try again."
          />
        </AlertMessage>
      )}
      <AutomaticFormRow<EmailTabFormValues>
        fullWidth
        form={form}
        name="email"
        registerOptions={{
          required: defaultRequiredMessage(intl),
          pattern: emailPatternValidation(intl),
        }}
        label={intl.formatMessage(MESSAGES.emailLabel)}
        required
        highlightLabelOnError
        dynamicHeightError
        as={EmailTextInput}
      />
      <Button
        className={Styles.emailButton}
        type="submit"
        variant="primary"
        buttonColor="action"
        isLoading={form.formState.isSubmitting}
      >
        <FormattedMessage id="28a78340-9c2d-44fc-ac0a-f4ed66b7d986" defaultMessage="Send email" />
      </Button>
    </form>
  );
}

export function ReferralShareModal({
  campaign,
  onDone,
}: {
  campaign: ReferralCampaign;
  onDone: () => void;
}) {
  const intl = useIntl();
  const { referralLink, handleCreateLink } = useCreateReferralLink();
  const referralsP2Enabled = useFeatureFlag(REFERRAL_CAMPAIGNS_P2);

  return (
    <WorkflowModal
      closeBehavior={{
        tag: "with-button",
        onClose: onDone,
      }}
      title={
        <FormattedMessage
          id="ec3f486b-8aec-4d7f-9c25-48870fde90a2"
          defaultMessage="{singleUse, select, true {Share your single-use} other {Share your} } referral link"
          values={{ singleUse: campaign.coverPayment }}
        />
      }
      large
      footerSeparator={false}
    >
      {referralsP2Enabled ? (
        <>
          {campaign.coverPayment ? (
            <AlertMessage className={Styles.banner} kind="success">
              <Paragraph>
                <FormattedMessage
                  id="96c4900c-0d79-40b3-b750-89b4194f9bce"
                  defaultMessage="Your single-use retail referral link (via <b>{campaignName}</b>) has been generated! Send a direct email to a signer, copy the URL, or create a QR code that the signer can scan via their mobile device."
                  values={{
                    campaignName: campaign.name,
                    b: (word) => <strong>{word}</strong>,
                  }}
                />
              </Paragraph>
            </AlertMessage>
          ) : (
            <Paragraph>
              <FormattedMessage
                id="eac352db-1038-4df2-acda-30074579cde6"
                defaultMessage="Use one of the following methods to share your retail referral link (via <b>{campaignName}</b>) with signers. You can send a direct email to a signer, share a URL, or download a QR code that the signer can scan via their mobile device."
                values={{
                  campaignName: campaign.name,
                  b: (word) => <strong>{word}</strong>,
                }}
              />
            </Paragraph>
          )}

          <TabRow
            className={Styles.modalTabs}
            marginBottom
            ariaLabel={intl.formatMessage(MESSAGES.shareTabsLabel)}
          >
            <TabButton
              title={
                <FormattedMessage
                  id="ec8980ba-c2ed-432e-87f8-ddaa8ce55790"
                  defaultMessage="Email"
                />
              }
              content={<EmailTab campaign={campaign} onDone={onDone} />}
            />
            <TabButton
              title={
                <FormattedMessage id="42bf0ff0-28fd-41ee-9461-a1f1025e0862" defaultMessage="URL" />
              }
              content={
                <CopyableTextArea
                  onMount={() => handleCreateLink(campaign.id)}
                  ariaLabel={intl.formatMessage(MESSAGES.copyableTextArea)}
                  value={referralLink?.link}
                  buttonText={
                    <FormattedMessage
                      id="ba8525b7-7975-4af4-ba75-2cee705e41b5"
                      defaultMessage="Copy URL"
                    />
                  }
                />
              }
            />
            <TabButton
              title={
                <FormattedMessage
                  id="cc220929-8142-47e4-b794-5ef14c1362ae"
                  defaultMessage="QR code"
                />
              }
              content={
                <GenerateQrCodeContent
                  onMount={() => handleCreateLink(campaign.id)}
                  name={campaign.name}
                  qrStream={referralLink?.qrStream}
                />
              }
            />
          </TabRow>
        </>
      ) : (
        <EmailTab campaign={campaign} onDone={onDone} />
      )}
    </WorkflowModal>
  );
}
