import { FormattedMessage } from "react-intl";

import { SidebarTabLink } from "common/sidebar";

export const ROUTE = "referrals";

export function ToolsSidebarReferralsLink() {
  return (
    <SidebarTabLink to={ROUTE}>
      <FormattedMessage
        id="d2884f65-5d4a-48d6-90c6-874aaee81632"
        defaultMessage="Referral campaigns"
      />
    </SidebarTabLink>
  );
}
