import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN,
  HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN,
  IDENTIFY_TRANSACTIONS,
} from "constants/feature_gates";
import { useProofTransactionsEnabled, useSignTransactionsEnabled } from "util/feature_detection";
import { useFeatureFlag } from "common/feature_gating";
import PopoutMenu from "common/core/popout_menu";
import { SidebarActionButton } from "common/sidebar";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import {
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  PROOF_TRANSACTION_TYPE,
} from "constants/transaction";
import { DashboardDropdownIcon } from "common/dashboard/dropdown";
import { isNotaryNST } from "common/notary/capacity";
import { TRANSACTION_CTA_MESSAGES } from "common/dashboard/constants";
import { NewBadge } from "common/core/announcements";

import { DotLoopPopoutItem } from "../dotloop";

type TransactionPopoutMenuProps = {
  dotloopEligible: boolean;
  handleImportLoop: () => void;
  orgIsActive?: boolean;
  notaryProfile: Parameters<typeof useProofTransactionsEnabled>[0];
};

const MESSAGES = defineMessages({
  proofTxn: {
    id: "c4f43c5e-7f78-4e8e-8082-a8fdb6f44205",
    defaultMessage: "Proof transaction request",
  },
});

// TODO remove in BIZ-7063
const DEPRECATED_MESSAGES = defineMessages({
  notarizeText: {
    id: "c5cf37da-caad-404c-b4dd-448c0cac4fb9",
    defaultMessage: "Notarization Request",
  },
  notarizeSubText: {
    id: "863a896f-e559-407c-99ab-daf6c9b7250c",
    defaultMessage: "Remote notarizations for non-closings",
  },
  signText: {
    id: "16461030-cb4d-474f-b189-73da2cfdce6d",
    defaultMessage: "eSignature Request",
  },
  signSubText: {
    id: "876e050d-48e6-4c19-94f9-11752deb37e6",
    defaultMessage: "Digital signatures without identity verification or notarization",
  },
  identifyText: {
    id: "72519b1a-7032-4710-8e5f-f769190014c5",
    defaultMessage: "Identify",
  },
  identifySubText: {
    id: "11d9123c-4ee3-43bc-8637-77cf529e5e0c",
    defaultMessage: "Confirm an identity with automated verification",
  },
});

export function NewTransactionPopoutMenu({
  dotloopEligible,
  handleImportLoop,
  orgIsActive = true,
  notaryProfile,
}: TransactionPopoutMenuProps) {
  const navigate = useNavigate();
  const intl = useIntl();
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const proofTransactionsEnabled =
    useProofTransactionsEnabled(notaryProfile) && !signTransactionsEnabled;
  const showEsignOption = !useFeatureFlag(HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN);
  const showNotarizationOption = !useFeatureFlag(HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN);
  const showIdentifyOption = useFeatureFlag(IDENTIFY_TRANSACTIONS) && !isNotaryNST(notaryProfile);

  // If customer doesn't have any available transaction creation options, simply hide the entire popout menu
  if (
    !dotloopEligible &&
    !showNotarizationOption &&
    !proofTransactionsEnabled &&
    !showEsignOption
  ) {
    return null;
  }
  const messages = signTransactionsEnabled ? TRANSACTION_CTA_MESSAGES : DEPRECATED_MESSAGES;

  return (
    <PopoutMenu
      placement="bottomRight"
      target={
        <SidebarActionButton
          disabled={!orgIsActive}
          disabledHint={
            !orgIsActive && (
              <FormattedMessage
                id="520842a7-1548-4c1b-b8e7-41a3e637f8ef"
                defaultMessage="You cannot send a new transaction because this organization is disabled."
              />
            )
          }
          disabledHintPlacement="bottom"
          automationId="new-transaction"
        >
          <FormattedMessage
            id="a77d78e3-c8bf-4262-bf07-9d11a129cf0c"
            defaultMessage="Send new transaction"
          />
          <DashboardDropdownIcon />
        </SidebarActionButton>
      }
    >
      {() => (
        <>
          {dotloopEligible && <DotLoopPopoutItem onClick={handleImportLoop} />}
          {showNotarizationOption && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-notarization-transaction"
              onClick={() => {
                navigate("/transaction/new");
              }}
              primaryContent={intl.formatMessage(messages.notarizeText)}
              secondaryContent={intl.formatMessage(messages.notarizeSubText)}
              iconName="notarize-filled"
            />
          )}
          {proofTransactionsEnabled && (
            <PopoutMenuMultilineItem
              link
              aria-label={intl.formatMessage(MESSAGES.proofTxn)}
              data-automation-id="new-proof-transaction"
              onClick={() => {
                navigate(`/transaction/new?type=${PROOF_TRANSACTION_TYPE}`);
              }}
              primaryContent={
                <FormattedMessage
                  id="1b5cdd12-70e9-4eef-a92d-f79266198641"
                  defaultMessage="Proof"
                />
              }
              secondaryContent={
                <FormattedMessage
                  id="7a0c3d88-606c-430e-ba40-b1ce3133707e"
                  defaultMessage="Secure, identity-verified signatures without notarization"
                />
              }
              iconName="evault"
            />
          )}
          {showEsignOption && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-esign-transaction"
              onClick={() => {
                navigate(`/transaction/new?type=${ESIGN_TRANSACTION_TYPE}`);
              }}
              primaryContent={intl.formatMessage(messages.signText)}
              secondaryContent={intl.formatMessage(messages.signSubText)}
              iconName="sign"
            />
          )}
          {showIdentifyOption && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-identify-transaction"
              onClick={() => {
                navigate(`/transaction/new?type=${IDENTIFY_TRANSACTION_TYPE}`);
              }}
              primaryContent={
                <>
                  {intl.formatMessage(messages.identifyText)}
                  {/* Remove in BIZ-6131 */}
                  <NewBadge />
                </>
              }
              secondaryContent={intl.formatMessage(messages.identifySubText)}
              iconName="secure"
            />
          )}
        </>
      )}
    </PopoutMenu>
  );
}
